import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    data_sources: []
  },
  mutations: {
    SET_DATA_SOURCES(state, value) {
      state.data_sources = value
    }
  },
  getters: {
    getDataSources(state) {
      return state.data_sources.map(d => ({ ...d, linked: d.datapoints.length !== 0 }))
    }
  },
  actions: {
    // eslint-disable-next-line
    getAllDataSourcesByEndDeviceId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getDataSources/end_device/' + id).then(res => {
          commit('SET_DATA_SOURCES', res.data.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getAllDataSourcesBySiteId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getDataSources/site/' + id).then(res => {
          commit('SET_DATA_SOURCES', res.data.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getVirtualDataSources({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/getVirtualDataSources').then(res => {
          commit('SET_DATA_SOURCES', res.data.data.data)
          resolve(res.data.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createDataSource(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createDataSource', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createVirtualDataSource(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/createVirtualDataSource', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    deleteDataSourcesHistory(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/removeElasticIndexes', data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    reaggregateDataSourcesHistory(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/reaggregateDataSourcesHistory', data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    deleteDataSource(_, id) {
      if (id instanceof Array) {
        return new Promise((resolve, reject) => {
          axios.axiosApiInstance.delete('/deleteDataSource', {
            data: id
          }).then(res => {
            resolve(res)
          }).catch((e) => {
            reject(e)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          axios.axiosApiInstance.delete('/deleteDataSource/' + id).then(res => {
            resolve(res)
          }).catch((e) => {
            reject(e)
          })
        })
      }
    },
    async updateVirtualDataSource(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateVirtualDataSource/' + data.id, data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateDataSource(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/updateDataSource', data).then(res => {
          resolve(res.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
